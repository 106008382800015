<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10326')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content" v-if="mmy0112.length > 0">
				<div class="box">
					<div class="bbs">
						<div class="bbs__count">
							총 <span class="bbs-num blue">{{totalItem}}</span> 건 <!-- 재번역 체크 -->
						</div>
						<ul class="bbs__list" v-if="mmy0112.length > 0">
							<li v-for="item in mmy0112" :key="item.SEQ">
								<div class="bbs__info">
									<router-link :to="`/mypage/inquiry/info/${item.SEQ}`">
										<span class="bbs__tag">
											{{item.QUES_CD}}
										</span>
										<p class="bbs__title">
											{{item.QUES_TITLE}}
										</p>
										<span class="bbs__date grey3">
											{{dayjs(item.DATE).format('YYYY.MM.DD HH:mm')}}
										</span>
										<span class="bbs__status" :class="item.STATUS === '접수중' ? 'ing' : 'eng'"><!-- 재번역 체크 -->
											{{item.STATUS}}
										</span>
									</router-link>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>
      <section class="content" v-else>
          <div class="centered centered--xlarge">
              <div class="centered__wrap">
                  <div class="textbox">
                      <div class="textbox__icon">
                          <img src="@/assets/images/img_warning.png" alt="작성하신 리뷰가 없습니다." style="width:64px">
                      </div>
                      <h3 class="textbox__title">
                          {{t('10333')}}
                      </h3>
                  </div>
              </div>
          </div>
      </section>
			<div class="pager" v-if="mmy0112.length > 0">
				<a @click="onClickStartPage">
					<i class="icon icon-first"></i>
				</a>
				<a @click="onClickPrevPage">
					<i class="icon icon-left--light"></i>
				</a>
				<a 
					v-for="index in Math.min(maxPage, 5)" 
					:key="index" 
					:class="{'active' : (page === pageOffset + index)}"
					@click="onClickMovePage(pageOffset + index)"
				>
					{{pageOffset + index}}
				</a>
				<a @click="onClickNextPage">
					<i class="icon icon-right--light"></i>
				</a>
				<a @click="onClickEndPage">
					<i class="icon icon-last"></i>
				</a>
			</div>
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm" @click="onClickWriteInquiry">{{t('10384')}}</button>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>


<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"
import { useRouter } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	setup: function() {
		const router = useRouter();
		const store = useStore();

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mmy0112 = computed(() => store.state.mmy01.mmy0112);

		const totalItem = computed(()=> mmy0112.value.length > 0	? mmy0112.value[0].TOTAL_RECORDS : 0);
		const maxPage = computed(() => Math.ceil(totalItem.value / 10));
		const page = ref(1);
		const startPage = computed(() => (page.value - 1)/5 * 5 + 1);
		const endPage = computed(() => Math.min(startPage.value + 4, maxPage.value));
		const pageOffset = computed(() => Math.max(Math.min(page.value - 3, maxPage.value - 5), 0));

		onMounted(()=>{
			if(isLogin.value){
				store.dispatch("mmy01/fetchMmy0112", {
					proc_cd: "01", // 01: 조회, 09: seq 문의 삭제
					mem_id: userData.value.mem_token,
					pagesize: 10,
					gotopage: 1
				});
			}
		});

		const updatePage = (index) => {
			if(page.value !== index){
				store.dispatch("mmy01/fetchMmy0112", {
					proc_cd: "01",
					mem_id: userData.value.mem_token,
					pagesize: 10,
					gotopage: index
				});
				page.value = index;
			}
		}

		const onClickMovePage = (index) => {
			updatePage(index);
		}

		const onClickNextPage = () => {
			if(page.value < maxPage.value){
				updatePage(page.value + 1);
			}
		}
		const onClickPrevPage = () => {
			if(page.value > 1){
				updatePage(page.value - 1);
			}
		}	

		const onClickEndPage = () => {
			updatePage(maxPage.value);
		}
		const onClickStartPage = () => {
			updatePage(1);
		}

		const onClickWriteInquiry = () => {
			router.push("/mypage/inquiry/write");
		};

		const goBack = () => {
			router.go(-1);
		}
		const { t }= useI18n() //번역필수 모듈

		return {
			userData,
			isLogin,
			goBack,
			onClickWriteInquiry,
			mmy0112,
			dayjs,
			maxPage,
			page,
			onClickMovePage,
			onClickNextPage,
			onClickPrevPage,
			onClickEndPage,
			onClickStartPage,
			startPage,
			endPage,
			pageOffset,
			totalItem,
			t,
			i18n
		}
	}
}
</script>